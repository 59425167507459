<template>
  <article class="mtp-reviews">
    <div class="wrapper">
      <h2 class="mtp-reviews__title">{{ title }}</h2>
    </div>
    <ClientOnly>
      <Swiper
        :modules="[Pagination, Navigation]"
        :slides-per-view="1.3"
        :space-between="16"
        create-elements
        :auto-height="false"
        :loop="reviews.length > 1"
        centered-slides
        pagination
        navigation
        :breakpoints="breakpoints"
      >
        <SwiperSlide v-for="(slide, index) in reviews" :key="index" class="mtp-reviews__slide">
          <div class="mtp-reviews__slide-head">
            <UIPictureTag
              v-if="slide.image.source"
              class="mtp-reviews__photo"
              :image-original="`${url}${slide.image.source}`"
              :image-seo="slide.imageSeo"
              :image-webp="`${url}${slide.image.webp}`"
              is-visible
            />
            <div class="mtp-reviews__name-wrapper">
              <h3 class="mtp-reviews__name">{{ slide.title }}</h3>
              <span class="mtp-reviews__date">{{ slide.descriptions }}</span>
              <span v-if="slide.date" class="mtp-reviews__date">{{ slide.date }}</span>
            </div>
          </div>
          <p class="mtp-reviews__text">
            {{ slide.text }}
          </p>
          <UIButton
            type="link"
            :link="{ name: 'home', params: { chapters: useSlugTransform(slide.slug) } }"
            variant="primary"
            size="l"
            label="Смотреть отзыв"
          />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </article>
</template>

<script setup lang="ts">
import './styles.scss';
import type { ImageItem, ImageSEO } from '~/types/pages';
import { useRuntimeConfig, useSlugTransform } from '#imports';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
  title: string;
  reviews: {
    image: ImageItem;
    imageSeo: ImageSEO;
    previewVideo: string;
    fullVideoLink?: string;
    title: string;
    descriptions: string;
    text: string;
    slug: string;
    date: string | null;
  }[];
}

defineProps<Props>();
const url = useRuntimeConfig().public.siteUrl;

const breakpoints = {
  1341: {
    spaceBetween: 64,
    slidesPerView: 3,
  },
  1025: {
    slidesPerView: 2,
    spaceBetween: 64,
  },
  769: {
    slidesPerView: 2,
    spaceBetween: 32,
  },
};
</script>
